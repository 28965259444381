@use 'sass:map';
@use '@angular/material' as mat;
@use 'light';

.mat-mdc-tab-links {
    .mat-mdc-tab-link .mdc-tab-indicator__content--underline,
    .mat-mdc-tab-link.mdc-tab--active:hover .mdc-tab-indicator__content--underline,
    .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab-indicator__content--underline  {
        border-color: mat.get-color-from-palette(light.$light-purple, 500);
    }

    .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label,
    .mat-mdc-tab-link.mdc-tab--active:hover .mdc-tab__text-label,
    .mat-mdc-tab-link.mdc-tab--active:hover .mdc-tab-indicator__content--underline,
    .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab__text-label{
        color: mat.get-color-from-palette(light.$light-purple, 500);
    }
}

