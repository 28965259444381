$dark-primary-text: #21212C;
$dark-secondary-text: #757575;
$dark-disabled-text: #757575;
$dark-dividers: #21212C;
$dark-focused: #21212C;

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$red-palette: (
  50: #faf0f2,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #DD2C51,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #921e31,
  A100: #faf0f2,
  A200: #DD2C51,
  A400: #ff1744,
  A700: #921e31,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

@each $name, $color in $red-palette {
  .text-red-#{$name} {
    @if type-of($color) == color {
      color: $color;
    }
  }
}

$purple-palette: (
    50: #f3e5f5,
    100: #e1bee7,
    200: #ce93d8,
    300: #ba68c8,
    400: #ab47bc,
    500: #9c27b0,
    600: #8e24aa,
    700: #7b1fa2,
    800: #6a1b9a,
    900: #4a148c,
    A100: #ea80fc,
    A200: #e040fb,
    A400: #d500f9,
    A700: #aa00ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

@each $name, $color in $purple-palette {
  .text-purple-#{$name} {
    @if type-of($color) == color {
      color: $color;
    }
  }
}


$purple-accent-palette: (
  50: #FBFBFE,
  100: #ECEDF9,
  200: #CECFF0,
  300: #6669CF,
  400: #575BCA,
  500: #3E31C3,
  600: #2E3191,
  700: #252773,
  800: #202264,
  900: #1B1D55,
  A100: #8c9eff,
  A200: #ECEDF9,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

@each $name, $color in $purple-accent-palette {
    .text-blue-#{$name} {
        @if type-of($color) == color {
            color: $color;
        }
    }
}

$green-palette: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #F0FAF2,
  400: #C9EED1,
  500: #34A34C,
  600: #20652F,
  700: #085305,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

@each $name, $color in $green-palette {
  .text-green-#{$name} {
    @if type-of($color) == color {
      color: $color;
    }
  }
}

$grey-palette: (
  50: #f6f6fa,
  100: #F3F3F6,
  200: #C4C5D4,
  300: #7B7C9D,
  400: #414258,
  500: #21212C,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

@each $name, $color in $grey-palette {
  .text-grey-#{$name} {
    @if type-of($color) == color {
      color: $color;
    }
  }
}

$orange-palette: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: black,
  )
);

$yellow-palette: (
    50: #fffde7,
    100: #fff9c4,
    200: #fff59d,
    300: #fff176,
    400: #ffee58,
    500: #ffeb3b,
    600: #fdd835,
    700: #fbc02d,
    800: #f9a825,
    900: #f57f17,
    A100: #ffff8d,
    A200: #ffff00,
    A400: #ffea00,
    A700: #ffd600,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $dark-primary-text,
        700: $dark-primary-text,
        800: $dark-primary-text,
        900: $dark-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $dark-primary-text,
    )
);

@each $name, $color in $orange-palette {
  .text-orange-#{$name} {
    @if type-of($color) == color {
      color: $color;
    }
  }
}

$light-blue-palette: (
    50: #e1f5fe,
    100: #b3e5fc,
    200: #81d4fa,
    300: #4fc3f7,
    400: #29b6f6,
    500: #03a9f4,
    600: #039be5,
    700: #0288d1,
    800: #0277bd,
    900: #01579b,
    A100: #80d8ff,
    A200: #40c4ff,
    A400: #00b0ff,
    A700: #0091ea,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: $light-primary-text,
    )
);

$lime-palette: (
    50: #e6ffeb,
    100: #c2ffcd,
    200: #94ffac,
    300: #4fff84,
    400: #00fb61,
    500: #00f547,
    600: #00e33b,
    700: #00ce2c,
    800: #00bc1e,
    900: #009a00,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    )
);

@each $name, $color in $lime-palette {
    .text-lime-#{$name} {
        @if type-of($color) == color {
            color: $color;
        }
    }
}
