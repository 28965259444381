@use 'sass:map';
@use '@angular/material' as mat;
@use 'light';

button.no-min-width {
  min-width: unset;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: inherit;

  &.mat-warn {
    border-top-color: mat.get-color-from-palette(light.$light-red, 500);

    &:hover {
      background: mat.get-color-from-palette(light.$light-red, 50);
      transition: background-color .2s ease-in-out;
    }
  }

  &.mat-primary {
      color: mat.get-color-from-palette(light.$light-purple, 600);

    &:hover {
      background: mat.get-color-from-palette(light.$light-purple, 100);
      transition: background-color .2s ease-in-out;

    }
  }
}

.mat-mdc-raised-button:not(:disabled) {
    border-color: inherit;

    &.mat-warn {
        border-top-color: mat.get-color-from-palette(light.$light-red, 500);

        &:hover {
            background: mat.get-color-from-palette(light.$light-red, 50);
            transition: background-color .2s ease-in-out;
        }
    }

    &.mat-primary {
        border-top-color: mat.get-color-from-palette(light.$light-purple, 600);
        background: mat.get-color-from-palette(light.$light-purple, 600);

        &:hover {
            background: mat.get-color-from-palette(light.$light-purple, 300);
            transition: background-color .2s ease-in-out;

        }
    }
}

.mat-button-wrapper {
  .mat-icon {
    position: relative;
    font-size: 1.25rem;

    &.top1 { top: 1px; }
    &.top2 { top: 2px; }
    &.top3 { top: 3px; }
    &.top4 { top: 4px; }
    &.top5 { top: 5px; }

    &.left-1 { left: -1px; }
    &.left-2 { left: -2px; }
    &.left-3 { left: -3px; }
    &.left-4 { left: -4px; }
    &.left-5 { left: -5px; }
  }
}

.mat-flat-neg {
  margin-left: -1.15rem !important;
}
