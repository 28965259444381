@use '@angular/material' as mat;
@use 'theme/light';
@use 'theme/typography' as typography;

//@include mat.core();
@include mat.typography-hierarchy(typography.$my-app-typography);
@include mat.core();
@include mat.all-component-themes(light.$my-app-light-theme);

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Symbols+Outlined");
@import 'theme/buttons';
@import 'theme/card';
@import 'theme/icons';
@import 'theme/inputs';
@import 'theme/lists';
@import 'theme/table';
@import 'theme/tabs';

:root {
    --green-color: #4FFF83;
    --black-color: #212529;
    --white-color: #ffffff;
    --folder-icon-color: #5f6368;
    --file-icon-color: #1A73E8;
    --border-color: #E0E0E0;
    --primary-color: #8e24aa;
    //--grey-color: mat.get-color-from-palette(light.$light-grey, 100);
    //--red-color: mat.get-color-from-palette(light.$light-red, 500);
    //--green-color: mat.get-color-from-palette(light.$light-green, 600);
    //--yellow-color: mat.get-color-from-palette(light.$light-yellow, 500);
    //--blue-color: mat.get-color-from-palette(light.$light-accent, 400);
    //--gray-color: mat.get-color-from-palette(light.$light-grey, 300);
    //--orange-color: mat.get-color-from-palette(light.$light-orange, 400);
    //--light-blue-color: mat.get-color-from-palette(light.$light-blue, 300);

}

html, body {
    height: 100%;
    font-size: 16px;
    color: var(--black-color);


    --knit-primary: var(--primary-color);
    --knit-grey: mat.get-color-from-palette(light.$light-grey, 300);
    --knit-branding: var(--green-color);
    --knit-branding-alt: rgba(255 244 188, 0.48);

}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

//.mat-typography {
//    font-family: Rubik, "Helvetica Neue", sans-serif !important;
//    font-size: 16px !important;
//}

.border-primary {
    border: 1px solid var(--border-color) !important;
}

.border-bottom-primary {
    border-bottom: 1px solid var(--border-color) !important;
}

.border-top-primary {
    border-top: 1px solid var(--border-color) !important;
}

.bg-grey {
    background-color: mat.get-color-from-palette(light.$light-grey, 100);
}

.bg-red {
    background-color: mat.get-color-from-palette(light.$light-red, 500);
}

.bg-green {
    background-color: mat.get-color-from-palette(light.$light-green, 600);
}

.bg-blue {
    background-color: mat.get-color-from-palette(light.$light-accent, 400);
}

.bg-light-blue {
    background-color: mat.get-color-from-palette(light.$light-blue, 300);
}

.bg-orange {
    background-color: mat.get-color-from-palette(light.$light-orange, 400);
}

.bg-yellow {
    background-color: mat.get-color-from-palette(light.$light-yellow, 500);
}

.bg-gray {
    background-color: mat.get-color-from-palette(light.$light-grey, 300);
}

.bg-purple {
    background-color: mat.get-color-from-palette(light.$light-purple, 600);
}

.bg-lightGreen {
    background-color: mat.get-color-from-palette(light.$light-green, A400);
}

.badge + .badge {
    margin-left: .5rem;
}

h1.oakmore-title {
    //text-decoration: underline;
    //text-decoration-color: mat.get-color-from-palette(light.$light-green, A400);
    margin-bottom: 0 !important;
    font-weight: bold !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    background: #CCCCCC;
    border-radius: 5px;
}

//select and menu panel
.mat-mdc-select-panel, .mat-mdc-autocomplete-panel, .mat-mdc-menu-panel {
    & mat-option.mat-mdc-option, & button.mat-mdc-menu-item {
        min-height: 36px !important;
        font-size: .875rem !important;
    }
}

.mat-mdc-menu-panel .mat-mdc-menu-item .mat-icon {
    margin-right: 8px;
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
}


.w-60 {
    width: 60%;
}
.w-40 {
    width: 40%;
}
