@use 'sass:map';
@use '@angular/material' as mat;
@use 'light';

.oakmore-card {
    border: 1px solid var(--border-color) !important;
    border-radius: 4px !important;
    box-shadow: none !important;

    .mat-mdc-card-title {

    }

    mat-card-content {
        padding: 0 !important;
    }
    mat-list {
        padding: 0 !important;

        mat-list-item {
            padding: .5rem 1rem !important;
            height: auto !important;
        }
    }
}
