@use 'sass:map';
@use '@angular/material' as mat;
@use 'light';

.oakmore-table {
    border: 1px solid var(--border-color) !important;
    border-radius: 4px !important;
    box-shadow: none !important;

    tr.mat-mdc-row:hover {
      background-color: mat.get-color-from-palette(light.$light-grey, 50);
  }

    td {
        text-align: left;
        vertical-align: middle;
    }
}
