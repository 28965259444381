@use 'sass:map';
@use '@angular/material' as mat;
@use 'light';

mat-list.mat-mdc-list-item{
    padding: 0 ;
}

mat-list-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

mat-list-item.mat-mdc-list-item, tr.mat-mdc-row {
    padding: 0;
    cursor: pointer;

    &:hover {
        background-color: var(--grey-color);
    }
}

.list-item-hover {
    &:hover {
        background-color: var(--grey-color);
    }
}

mat-selection-list mat-list-option.mdc-list-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color);

    &:hover {
        background-color: var(--grey-color) !important;
    }
}

button.mdc-icon-button:hover, button.mdc-icon-button:active {
    //background-color: transparent !important;
}

.mat-mdc-list-item-title.mdc-list-item__primary-text, {
    color: var(--black-color);

    a {
        color: var(--black-color);

        &:hover {
            color: var(--primary-color);
        }
    }
}
