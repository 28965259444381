@use '@angular/material' as mat;

$heading-font-family: Roboto, "Helvetica Neue", sans-serif;

$my-app-typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, $font-family: $heading-font-family),
  $headline-2: mat.define-typography-level(56px, $font-family: $heading-font-family),
  $headline-3: mat.define-typography-level(45px, $font-family: $heading-font-family),
  $headline-4: mat.define-typography-level(1.5rem, $font-family: $heading-font-family),
  $headline-5: mat.define-typography-level(24px, $font-family: $heading-font-family),

);

.text-sm {
    font-size: .875rem;
}
mat-icon.text-sm, img.text-sm {
    font-size: .875rem;
    height: .875rem;
    width: .875rem;
}

.text-xs {
    font-size: .75rem;
}

mat-icon.text-xs, img.text-xs {
    font-size: .75rem;
    height: .75rem;
    width: .75rem;
}
.text-xxs {
    font-size: .625rem;
}

mat-icon.text-xxs, img.text-xxs {
    font-size: .625rem;
    height: .625rem;
    width: .625rem;
}

.text-xxxs {
    font-size: .5rem;
}

mat-icon.text-xxxs, img.text-xxxs {
    font-size: .5rem;
    height: .5rem;
    width: .5rem;
}

.text-normal {
    font-size: 1rem;
}

mat-icon.text-normal, img.text-normal {
    font-size: 1rem;
    height: 1rem;
    width: 1rem;
}

.text-lg {
    font-size: 1.25rem;
}

mat-icon.text-lg, img.text-lg {
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
}

.text-xl {
    font-size: 1.5rem;
}

mat-icon.text-xl, img.text-xl {
    font-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
}

.text-xxl {
    font-size: 2rem;
}

mat-icon.text-xxl, img.text-xxl {
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
}

.text-primary {
    color: var(--primary-color) !important;
}

a:hover {
    color: var(--primary-color);
}

.fw-400 {font-weight: 400;}
.fw-500 {font-weight: 500;}
.fw-700 {font-weight: 700;}

.icon-sm {font-size: 1.2rem !important; height: 1.2rem !important; width: 1.2rem !important;}
