@use "sass:map";
@use "@angular/material" as mat;
@use "colors" as colors;

$light-primary: mat.define-palette(colors.$purple-palette);
$light-accent: mat.define-palette(colors.$purple-accent-palette);
$light-warn: mat.define-palette(colors.$red-palette);
$light-purple: mat.define-palette(colors.$purple-palette);
$light-green: mat.define-palette(colors.$green-palette);
$light-red: mat.define-palette(colors.$red-palette);
$light-grey: mat.define-palette(colors.$grey-palette);
$light-orange: mat.define-palette(colors.$orange-palette);
$light-yellow: mat.define-palette(colors.$yellow-palette);
$light-blue: mat.define-palette(colors.$light-blue-palette);
$light-lime: mat.define-palette(colors.$lime-palette);

$my-app-light-theme: mat.define-light-theme(
    (
      color: (
        primary: $light-primary,
        accent: $light-accent,
        warn: $light-warn,
        purple: $light-purple,
        green: $light-green,
        red: $light-red,
        grey: $light-grey,
        orange: $light-orange,
        lime: $light-lime
      ),
    )
);

.mat-secondary {
  color: mat.get-color-from-palette($light-purple, 500);
  background: mat.get-color-from-palette($light-purple, 100);

  &:hover {
    background: mat.get-color-from-palette($light-purple, 500);
    color: mat.get-color-from-palette($light-purple, 100);
  }
}
